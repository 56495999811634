<template>
  <!-- email START -->
  <div class="account-email">
    <!-- form:email START -->
    <el-form
      status-icon
      :rules="rules"
      ref="registerForm"
      :model="registerForm"
      class="login-form"
      :statusIcon="false"
      @submit.prevent.native="submitForm('registerForm')"
    >
      <!-- form:email:email START -->
      <el-form-item prop="email">
        <el-input
          inputmode="email"
          :placeholder="`${$t('login.email')} *`"
          v-model="registerForm.email"
          @keyup.enter.native="submitForm('registerForm')"
        ></el-input>
      </el-form-item>
      <!-- form:email:email END -->

      <!-- form:email:email:confirm START -->
      <el-form-item prop="confirmEmail">
        <el-input
          inputmode="email"
          :placeholder="`${$t('login.confirmemail')} *`"
          v-model="registerForm.confirmEmail"
          @keyup.enter.native="submitForm('registerForm')"
        ></el-input>
      </el-form-item>
      <!-- form:email:email:confirm END -->

      <!-- form:email:password START -->
      <el-form-item prop="password">
        <el-input
          :type="type"
          :placeholder="`${$t('login.password')} *`"
          v-model="registerForm.password"
          @keyup.enter.native="submitForm('registerForm')"
          autocomplete="off"
        >
          <i
            slot="suffix"
            class="el-icon-view"
            v-on:click="showHidePassword()"
          ></i>
        </el-input>
      </el-form-item>
      <!-- form:email:password END -->

      <!-- form:email:submit START -->
      <el-form-item>
        <el-button
          class="btn-default"
          type="primary"
          v-on:click="submitForm('registerForm')"
        >{{ $t('login.save') }}</el-button>
      </el-form-item>
      <!-- form:email:submit END -->
    </el-form>
    <!-- form:email END -->

    <!-- form:email:loading START -->
    <loading-default
      :loadingVisible="loadingVisible"
      :headline="loadingHeadline"
      :excerpt="loadingExcerpt"
    />
    <!-- form:email:loading END -->

  </div>
  <!-- email END -->
</template>

<script>
import titleMixin from '@/mixins/titleMixin';
import validateMixin from '@/mixins/validateMixin';

export default {
  name: 'email',
  mixins: [titleMixin, validateMixin],
  components: {
    LoadingDefault: () => import('../../components/shared/loading/Default.vue'),
  },
  data() {
    return {
      /**
       * Title (Page) - If "pageTitleFrom" exists - show simple Navigation...
       */
      pageTitleFromComponent: this.$t('account.email.headline'),

      /**
       * Loading
       */
      loadingVisible: false,
      loadingHeadline: this.$t('loading.update.headline'),
      loadingExcerpt: this.$t('loading.update.excerpt'),

      /**
       * Form (Variables)
       */
      serverErrorMessages: [],
      globalErrorMessage: false,
      type: 'password',

      /**
       * Form (Models) + Pass data to mixin
       */
      confirmationEmail: this.$t('login.confirmationemail'),
      registerForm: {
        email: '',
        confirmEmail: '',
        password: '',
      },
      /**
       * Form (Rules - Validation)
       */
      rules: {
        email: [
          {
            required: true,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
          {
            type: 'email',
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        confirmEmail: [
          {
            required: true,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
          {
            validator: this.validateConfirmEmail,
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        password: [
          {
            required: true,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
          {
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      const formData = new FormData();
      if (formName === 'registerForm') {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loadingVisible = true;
            /**
             * Locale
             */
            formData.append('locale', localStorage.getItem('lang'));
            /**
             * Data
             */
            formData.append('email', this.registerForm.email);
            formData.append('confirmEmail', this.registerForm.confirmEmail);
            formData.append('password', this.registerForm.password);

            // Display the key/value pairs
            /* [...formData.entries()].forEach((row) => {
              console.log(`${row}`);
            }); */

            // Update user data
            this.$http.post(`${process.env.VUE_APP_BASE_URL}user/email`, formData, {
              headers: {
                'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
                Authorization: `Bearer ${localStorage.getItem('auth')}`,
              },
            })
              .then((response) => {
                if (response.data) {
                  // Close loading
                  this.loadingVisible = false;

                  const user = response.data;

                  // mutating to store for client rendering &
                  // saving user data in cookie for server rendering
                  this.updateItem('user', JSON.stringify(user));

                  // notification - error
                  this.success();
                } else {
                  this.globalErrorMessage = true;
                  this.resetForm.agbs = false;

                  console.log('error register submit!!');
                }
              })
              .catch((error) => {
                if (!error.response) {
                  // network error
                  this.errorStatus = 'Error: Network Error';
                } else {
                  this.errorStatus = error.response.data;
                  this.loadingVisible = false;
                  this.serverErrorMessages = this.errorStatus.errors;

                  this.serverErrorMessages.forEach((message) => {
                    this.error(`${this.$t(`error.${message.code}`)}`);
                  });
                }
              });
          }
        });
      }
    },

    resetForm(formName) {
      if (this.$refs[formName]) {
        this.globalErrorMessage = false;
        this.$refs[formName].resetFields();
        this.type = 'password';
      }
    },

    showHidePassword() {
      if (this.type === 'password') {
        this.type = 'text';
      } else {
        this.type = 'password';
      }
    },

    updateItem(item, value) {
      const prevData = JSON.parse(localStorage.getItem(item));
      Object.keys(value).forEach((val) => {
        prevData[val] = value[val];
      });
      localStorage.setItem(item, JSON.stringify(prevData));
    },

    success() {
      this.$notify({
        message: this.$t('notification.success'),
        type: 'success',
        position: 'bottom-right',
      });

      // clean up input fields
      setTimeout(() => {
        this.resetForm('registerForm');
      }, 750);
    },

    error(message) {
      this.$notify({
        message: this.$t(`${message}`),
        type: 'error',
        position: 'bottom-right',
      });
    },
  },
};
</script>
